.tarzan-page-container{
    padding-top: 150px;
    background: rgb(24,2,111);
    color: white;
    background: linear-gradient(90deg, rgba(24,2,111,1) 0%, rgba(201,64,101,1) 100%);

    p{
        padding-left: 30px;
        padding-right: 30px;
    }
}

.content-section {
    background-color: rgba(255, 255, 255, 0.2);
    padding: 30px;
    border-radius: 20px;
}
