.blogPage {
  background: rgb(24, 2, 111);
  background: linear-gradient(90deg, rgba(24, 2, 111, 1) 0%, rgba(201, 64, 101, 1) 100%);
  color: #f8f9fa;
  padding-top: 80px;
  font-family: Arial, sans-serif;
}

.pageTitle {
  font-size: 36px;
  font-weight: bold;
  text-align: center;
  margin-bottom: 40px;
  color: #ff00ff;
}

.pageSubtitle {
  text-align: center;
  color: #b3b3b3;
  font-size: 18px;
  margin-bottom: 20px;
}

.blogCard {
  background: #333;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.5);
  overflow: hidden;
  margin-bottom: 30px;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  display: flex;
  height: 600px;
  flex-direction: column;
}

.blogCard:hover {
  transform: translateY(-10px);
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.75);
}

.blogImage {
  width: 100%;
  height: 220px;
  object-fit: cover;
  transition: transform 0.3s ease;
}

.blogImage:hover {
  transform: scale(1.05);
}

.blogContent {
  padding: 20px;
  text-align: center;
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.blogTitle {
  font-size: 24px;
  font-weight: bold;
  color: #ff00ff; 
  margin-bottom: 10px;
}

.blogDescription {
  font-size: 16px;
  color: #b3b3b3; 
  margin-bottom: 20px;
}

.blogMeta {
  font-size: 14px;
  color: #8c8c8c; 
  margin-bottom: 20px;
  padding: 10px 0;
  border-top: 1px solid #444;
}

.readMoreButton {
  display: inline-block;
  background: linear-gradient(90deg, #ff00ff, #d5006d);
  color: #fff;
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  text-decoration: none;
  transition: background-color 0.3s ease, transform 0.3s ease;
}

.readMoreButton:hover {
  background: linear-gradient(90deg, #d5006d, #ff00ff);
  transform: scale(1.05);
}

.overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.8); 
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.modal {
  width: 80vw;
  max-width: 900px;
  background-color: #2c2c2c;
  border-radius: 12px;
  padding: 30px;
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.5);
  overflow-y: auto;
  max-height: 80vh;
  position: relative;
  top: 6vh; 
}

.modalContent {
  position: relative;
  text-align: left;
  color: #f8f9fa; 
}

.closeButton {
  position: absolute;
  top: -30px;
  right: -20px;
  background: none;
  border: none;
  font-size: 43px;
  color: #ff00ff;
  cursor: pointer;
  transition: color 0.3s ease;
  z-index: 1001;
}

.closeButton:hover {
  color: #d5006d;
}

.modalTitle {
  font-size: 32px;
  font-weight: bold;
  line-height: 32px;
  margin-bottom: 10px;
  color: #ff00ff; 
}

.modalSubtitle {
  font-size: 20px;
  line-height: 20px;
  margin-bottom: 15px;
  color: #ccc; 
}

.modalMeta {
  font-size: 14px;
  line-height: 14px;
  color: #999;
  margin-bottom: 10px;
}

.modalBreaker {
  width: 100%;
  height: 2px;
  border-radius: 10px;
  background-color: #444;
  margin-bottom: 20px;
}

.modalText {
  font-size: 16px;
  line-height: 1.6;
  margin-bottom: 20px;
}

.modalImage {
  width: 100%;
  height: auto;
  margin-bottom: 15px;
  border-radius: 8px;
  transition: transform 0.3s ease;
}

.modalImage:hover {
  transform: scale(1.03);
}

.imageGallery {
  margin-top: 30px;
  text-align: center;
}

.imageGallery img {
  max-width: 100%;
  width: auto;
  height: auto;
  margin: 10px 0;
}

.imageGallery h4 {
  font-size: 1.2rem;
  margin-top: 20px;
}

.commentSection {
  margin-top: 30px;
  border-top: 2px solid #444;
  padding-top: 20px;
}

.commentList {
  margin-bottom: 20px;
}

.comment {
  background: #333;
  padding: 10px;
  border-radius: 8px;
  margin-bottom: 15px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.comment p {
  margin: 0;
  color: #f8f9fa;
}

.commentInput {
  width: 100%;
  padding: 10px;
  margin-bottom: 15px;
  font-size: 14px;
  border: 1px solid #444;
  border-radius: 8px;
  background-color: #333;
  color: #f8f9fa;
  resize: vertical;
}

.commentInput::placeholder {
  color: #b3b3b3;
}

.commentSubmitButton {
  background: linear-gradient(90deg, #ff00ff, #d5006d);
  color: white;
  border: none;
  padding: 12px 20px;
  font-size: 16px;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.commentSubmitButton:hover {
  background: linear-gradient(90deg, #d5006d, #ff00ff);
  transform: scale(1.05);
}

.commentSubmitButton:disabled {
  background: #ccc;
  cursor: not-allowed;
}

.voteSection {
  display: flex;
  justify-content: center;
  margin: 20px 0;
}

.voteButton {
  background: transparent;
  border: none;
  color: #b3b3b3; 
  font-size: 24px; 
  cursor: pointer;
  padding: 10px;
  transition: color 0.3s ease, transform 0.3s ease;
  margin: 0 10px; 
}

.voteButton:hover {
  color: #ff00ff; 
  transform: scale(1.1); 
}

.voteButton.active {
  color: #ff00ff; 
}

.voteButton:focus {
  outline: none; 
}

.voteCount {
  margin: 0 10px;
  font-size: 18px;
  color: #b3b3b3; 
}

.commentTitle {
  font-size: 1.8rem;
  font-weight: bold;
  margin-bottom: 1rem;
  text-align: center;
}

.commentHeader {
  font-size: 1.3rem;  
  font-weight: bold;
  margin-top: 1rem;  
  margin-bottom: 0.5rem;  
  text-align: center;
}

@media (max-width: 768px) {
  .modal {
    width: 95vw; 
    padding: 20px; 
    top: 6vh; 
  }

  .modalTitle {
    font-size: 24px; 
    margin-bottom: 8px; 
  }

  .modalSubtitle {
    font-size: 16px; 
    margin-bottom: 12px; 
  }

  .modalMeta {
    font-size: 12px; 
  }

  .modalText {
    font-size: 14px; 
    line-height: 1.4; 
  }

  .content-section{
    padding: 10px;
  }

  .voteButton {
    font-size: 20px; 
    padding: 8px;
  }

  .voteCount {
    font-size: 14px; 
  }

  .commentTitle {
    font-size: 1.6rem; 
  }

  .commentInput {
    font-size: 14px; 
    padding: 8px;
  }

  .commentSubmitButton {
    font-size: 14px; 
    padding: 10px 15px; 
  }

  .closeButton {
    top: -33px; 
    right: -17px;
    font-size: 35px; 
  }
}

.authorLink {
  color: #ff00ff; 
  text-decoration: none; 
  transition: color 0.3s ease; 
}

.authorLink:hover {
  text-decoration: underline; 
  color: #d5006d; 
}
