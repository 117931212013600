.tarzan-page-container{
    padding-left: 30px;
    padding-right: 30px;
    display: flex;
    flex-direction: column;
    gap: 40px;
    padding-bottom: 70px;
    animation: fadeIn 1.2s ease-in-out;
    .tarzan-page-heading-container{
        
        border: 5px solid white;
        border-radius: 20px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        background: rgb(192,120,71);
        background: linear-gradient(337deg, rgba(192,120,71,1) 0%, rgba(24,2,111,1) 0%, rgba(129,0,172,1) 100%);
        // background-color: rgb(0, 162, 255);
        color: white;
        height: 200px;
        text-align: center;
        p{
            font-size: 22px;
        }

        h1{
            text-shadow: none;
        }
    }
}
@keyframes fadeIn {
    from { opacity: 0; }
    to { opacity: 1; }
}

.content-listing{
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.tarzan-page-section-3-container, {
    display: flex;
    flex-direction: column;
    gap: 40px;
}
.projectRegistration {
    margin-top: 35px;
    margin-bottom: 40px;
    padding: 30px;
    background-color: rgba(255, 255, 255, 0.2); 
    border-radius: 20px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);  
}