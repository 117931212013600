body {
    margin: 0;
    padding: 0;
    font-family: 'Roboto', sans-serif;
}

.projects1 {
    background: linear-gradient(90deg, rgba(24,2,111,1) 0%, rgba(201,64,101,1) 100%);
   
    position: relative;
    overflow: hidden;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    padding-top: 100px; 
    color: #fff;
    animation: fadeIn 1.2s ease-in-out;
    
}

@keyframes fadeIn {
    from { opacity: 0; }
    to { opacity: 1; }
}

.projects1::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
   
    z-index: 1;
}

.content {
    position: relative;
    z-index: 2;
    margin: 0 auto;
    padding: 40px;
    border-radius: 20px;
    transition: transform 0.3s ease, background-color 0.3s ease;
}

.title {
    border: 5px solid white;
    border-radius: 20px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
   
    background: linear-gradient(337deg, rgba(192,120,71,1) 0%, rgba(24,2,111,1) 0%, rgba(129,0,172,1) 100%);
    
    color: white;
    height: 200px;
    text-align: center;
    margin-bottom: 35px;
}

 @keyframes titleAnimation {
    from { transform: translateY(-30px); opacity: 0; }
    to { transform: translateY(0); opacity: 1; }
} 

.aboutwebsite, .websitefeatures, .webtechnologies, .projectRegistration {
    margin-top: 35px;
    margin-bottom: 40px;
    padding: 30px;
    background-color: rgba(255, 255, 255, 0.2); 
    border-radius: 20px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    transition: transform 0.3s ease;
}


.aboutwebsite p, .websitefeatures ol, .webtechnologies ol {
    font-size: 18px;
    color: #f5f5f5;
}

