* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

.header {
  position: relative;
  z-index: 2;
  transition: all 0.3s ease;
  padding: 0;
  margin: 0;
  padding: 0;
}

.navbar {
  background: linear-gradient(90deg, rgb(0, 0, 0) 0%, rgb(75, 1, 64) 50%, rgb(54, 1, 75) 100%);
  transition: all 0.3s ease;
  border-radius: 0 0 15px 15px;
  border: none; 
  margin: 0;
  padding: 0;
}

.navbar-dark-scrolled {
  background: rgba(132, 73, 184, 0.9);
  border-bottom: 2px solid #aa00ff;
  transform: scale(0.98);
}

.container {
  max-width: 100vw;
  margin: 0;
  padding: 0;
}

.navbar-brand {
  justify-self: center;
}

.nav-item {
  position: relative;
  padding-left: 0.5em;
  padding-right: 0.5em;
  font-size: 18px;
}

.nav-link {
  color: #ffffffcc !important;
  transition: color 0.3s ease, transform 0.3s ease;
}

.nav-link.active {
  color: #a600ff;
  font-weight: bold;
}

.nav-link:hover {
  color: #a600ff;
  transform: scale(1.05);
  text-shadow: 0px 0px 5px rgba(123, 0, 255, 0.7);
}

.navbar-toggler {
  border: none;
  display: block;
}

.navbar-toggler-icon {
  display: flex;
  align-items: center;
  justify-content: center;
}

.scrolled .navbar {
  border-bottom: 2px solid #ff0084;
  transition: border-bottom 0.3s ease;
}

.navbar .container {
  display: flex;
  justify-content: center;
}

.navbar .navbar-collapse {
  display: flex;
  justify-content: center;
  width: 100%;
}

.navbar-nav {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  width: 100%;
  padding-left: 0; 
}

.navbar-brand .logo-img {
  height:min-content;  
  max-width:40px; 
  max-height: 40px; 
}

@media (max-width: 991px) {
  .navbar .container {
    justify-content: flex-start; 
  }
  .navbar .navbar-collapse {
    justify-content: flex-start;
  }
  .navbar-nav {
    justify-content: flex-start; 
  }
  .navbar-brand .logo-img {
    max-height: 100%; 
  }
}