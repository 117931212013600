:root {
  --light-bg: rgba(255, 255, 255, 0.8);
  --dark-text: #212529;
  --heading-color: #ffffff;
}

.refund {
    display: flex;
    flex-direction: column;
    min-height: 100vh; 
    background: url('img.jpeg') no-repeat center center,
                url('https://www.transparenttextures.com/patterns/cubes.png');
    background-size: cover, 50px 50px;
    background-attachment: fixed;
    color: rgba(255, 255, 255, 0.886);
    position: relative;
    overflow: hidden;
}


.refund::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: linear-gradient(135deg, rgba(237, 47, 98, 0.7) 0%, rgba(2, 2, 39, 0.7) 75%, rgba(19, 95, 225, 0.7) 100%);
  z-index: 1;
  transition: opacity 0.2s ease;
}

.refund-container {
  position: relative;
  z-index: 2;
  padding: 2rem;
}

.refund-container h1, .refund-container h3 {
  color: var(--heading-color);
  margin: 0;
}

.refund-container h1 {
  font-size: 48px;
  text-align: center;
  text-shadow: 2px 2px 6px rgba(0, 0, 0, 0.4);
}

.refund-container h3 {
  font-size: 21px;
  text-align: center;
  padding-bottom: 50px;
  text-shadow: 1px 1px 4px rgba(0, 0, 0, 0.3); 
}


.policy-card {
  background-color: var(--light-bg);
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.15); 
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.policy-card:hover {
  transform: translateY(-8px); 
  box-shadow: 0 6px 20px rgba(0, 0, 0, 0.25); 
}

.cards-container {
  color: var(--dark-text);
  gap: 40px;
  display: flex;
  flex-direction: column;
}

.cards-container h2 {
  font-size: 21px;
}

.text {
  color: var(--heading-color);
}

