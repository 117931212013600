.content-container{
    display: flex;
    flex-direction: column;
    gap: 30px;
    .content-section{
        background-color: rgba(255, 255, 255, 0.11);
        // pad: 10px;
        padding: 30px;
        border-radius: 20px;
    }
}