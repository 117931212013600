.gallery-container{
    padding-top: 150px;
    overflow-y: auto;
    padding-bottom: 150px;
    background: rgb(24,2,111);
    color: white;
    background: linear-gradient(90deg, rgba(24,2,111,1) 0%, rgba(201,64,101,1) 100%);
    .gallery-heading-container{
        text-align: center;
        padding-bottom: 50px;
        span{
            font-size: '42px';
        }
    }

    .gallery-blocks-container{
        padding-left: max(15px, 10vw);
        padding-right: max(15px, 10vw);
        display: flex;
        flex-direction: column;
        gap: 70px;
        
        .gallery-breaker{
            width: 100%;
            height: 4px;
            border-radius: 10px;
            background-color: rgba(255, 255, 255, 0.826);
        }

        .gallery-block{
            padding-left: 30px;
            padding-right: 30px;
            padding-top: 30px;
            padding-bottom: 30px;
            border-radius: 15px;
            background-color: rgba(255, 255, 255, 0.342);
            .gallery-content{
                display: flex;
                flex-direction: column;
                h2{
                    font-family:'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
                    font-size: '36px';
                    text-decoration: 'bold';
                }
                p{
                    font-family:'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
                }
            }
        }
    
    }
    .carousel-container{
        
        display: flex;
        width: 100%;
        height: 100%;
        justify-content: center;
        align-items: center;
        .carousel{
            // padding-top: 650px;
            display: flex;
            justify-content: center;
            align-items: center;
        }
    }
}