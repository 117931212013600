.background-video {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    z-index: -1; 
    opacity: 1; 
  }
  
  .hero {
      position: relative;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      padding: 60px 20px;
      background: linear-gradient(to right, rgba(23, 37, 90, 0.8), rgba(201, 64, 101, 0.8));
      width: 100%;
      height: 100vh;
      text-align: center;
      overflow: hidden;
  }
  
  .hero-content {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
      width: 80%;
      max-width: 1200px;
      text-align: left;
  }
  
  .hero-image {
      width: 300px;
      height: 300px;
      border-radius: 50%;
      object-fit: cover;
      margin-right: 20px;
      box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  }
  
  .hero-text {
      max-width: 600px;
  }
  
  .hero-text h2 {
      font-size: 36px;
      margin-bottom: 10px;
      color: #fff;
  }
  
  .hero-text p {
      text-align: justify;
      font-size: 18px;
      color: #fff;
      max-width: 600px;
      margin: 0 auto;
      line-height: 1.6;
      padding-bottom: 10px;
  }
  
  .social-links {
    display: flex; 
    justify-content: center;
    margin-top: 2rem;
  }
  
  .social-links a {
      margin: 0 15px;
      font-size: 18px;
      color: #fff;
      text-decoration: none;
      transition: color 0.3s ease;
  }
  
  .social-links a:hover {
      color: #005582;
      text-decoration: underline;
  }
  
  .social-links img.icon {
    width: 25px;
    height: 25px;
    margin-right: 8px;
  }
  
  .gmail-icon {
    height: 19px; 
    width: 25px; 
    margin-right: 8px; 
  }
  
  .social-links a:nth-child(3) {
    color: white;
    text-decoration: none; 
  }
  
  .section-links {
    display: flex; 
    justify-content: center;
    margin-top: 2rem;
  }
  
  .section-links a {
      display: inline-block;
      margin: 10px 15px;
      font-size: 18px;
      text-decoration: none;
      color: #fff;
      border-bottom: 2px solid transparent;
      transition: border-color 0.3s ease;
  }
  
  .section-links a:hover {
      border-color: #0077b5;
  }
  
  section {
      padding: 40px;
      margin-top: 20px;
      background: linear-gradient(to right, rgba(23, 37, 90, 0.8), rgba(201, 64, 101, 0.8));
      border-radius: 10px;
      box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  }
  
  section h2 {
      font-size: 28px;
      margin-bottom: 10px;
      color: #fff;
      text-align: center;
  }
  
  section p {
      font-size: 16px;
      color: #fff;
      line-height: 1.6;
      text-align: center;
  }
  
  .projects-grid {
      display: grid;
      grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
      gap: 50px;
      margin: 20px 0;
      justify-items: center;
  }
  
  .project-card {
      display: flex;
      flex-direction: column;
      justify-content: space-between; 
      background: rgba(23, 37, 90, 0.8);
      border-radius: 10px;
      text-align: center;
      padding: 15px;
      box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
      transition: transform 0.3s ease, box-shadow 0.3s ease;
  }
  
  .project-card:hover {
      transform: translateY(-5px);
      box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2);
  }
  
  .project-image {
      width: 100%;
      height: 250px;
      border-radius: 10px 10px 10px 10px;
      object-fit: cover;
  }

  .project-details {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    flex-grow: 1;
    padding: 10px;
  }
  
  .project-title {
    font-size: 1.2rem;
    color: #fff;
    margin-bottom: 10px;
    text-align: center;
  }
  
  .project-points {
    list-style-type: disc;
    margin: 10px 0;
    padding-left: 20px;
    text-align: left;
  }
  
  .project-points li {
    margin-bottom: 5px;
    color: #fff;
  }
  
  .project-button {
    display: inline-block;
    margin-top: auto; /* Ensures it stays at the bottom */
    padding: 10px 20px;
    background: #007bff;
    color: white;
    text-decoration: none;
    border-radius: 5px;
    transition: background 0.3s ease;
  }
  
  .project-button:hover {
    background: #0056b3;
  }
  
  
  .experience-container {
      justify-content: center;
      align-items: center;
      display: flex;
      flex-direction: column;
      gap: 20px;
      width: 80%;
      padding: 40px 0;
      margin: 0 auto;
      background: linear-gradient(to right, rgba(23, 37, 90, 0.8), rgba(201, 64, 101, 0.8));
      border-radius: 8px;
      box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    }
    
    .experience-card {
      width: 95%;
      display: flex;
      align-items: flex-start;
      background: rgba(23, 37, 90, 0.8);
      padding: 20px;
      border-radius: 8px;
      box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    }
    
    .experience-logo {
      width: 75px;
      height: 75px;
      margin-right: 20px;
      object-fit: cover;
      border-radius: 50%;
    }
    
    .experience-details {
      flex: 1;
      color: #fff;
    }
    
    .experience-header {
      display: flex;
      justify-content: space-between;
      align-items: baseline;
    }
    
    .experience-company {
      font-size: 1.2em;
      font-weight: bold;
    }
    
    .experience-date {
      font-size: 0.9em;
      color: #fff;
    }
    
    .experience-subheader {
      display: flex;
      justify-content: space-between;
      align-items: baseline;
      margin: 5px 0 10px;
    }
    
    .experience-role {
      font-weight: bold;
      font-size: 1em;
    }
    
    .experience-location {
      font-size: 0.9em;
      color: #fff;
    }
  
  #skills {
    padding: 50px 20px;
    background: linear-gradient(to right, rgba(23, 37, 90, 0.8), rgba(201, 64, 101, 0.8)); 
    color: #fff;
  }
  
  .skills-container {
    display: flex;
    flex-wrap: wrap;
    gap: 30px;
    justify-content: center;
  }
  
  .skills-category {
    width: 100%;
    max-width: 300px;
    background: rgba(23, 37, 90, 0.8); 
    padding: 20px;
    border-radius: 10px;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
    text-align: center;
  }
  
  .skills-category h3 {
    font-size: 1.5rem;
    color: #fff; 
    margin-bottom: 15px;
    border-bottom: 2px solid #ddd;
    padding-bottom: 5px;
  }
  
  .skills-list {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(100px, 1fr));
    gap: 15px;
    margin-top: 15px;
  }
  
  .skill-box {
    background:  rgba(201, 64, 101, 0.8);
    opacity: 0.95;
    padding: 20px;
    border-radius: 10px;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
    text-align: center;
    transition: transform 0.3s ease, box-shadow 0.3s ease;
  }
  
  .skill-box:hover {
    transform: translateY(-5px);
    box-shadow: 0 8px 20px rgba(0, 0, 0, 0.2);
  }
  
  .skill-box img {
    width: 50px;
    height: 50px;
    margin-bottom: 10px;
  }
  
  .skill-box p {
    font-size: 14px;
    color: #fff; 
    margin: 0;
  }
  
  .roles-achievements-container {
    background: linear-gradient(to right, rgba(23, 37, 90, 0.8), rgba(201, 64, 101, 0.8));
    display: flex;
    justify-content: space-between;
    gap: 20px;
    margin-top: 20px;
    padding: 20px;
    border-radius: 10px;
  }
  
  .roles-box, .achievements-box {
    flex: 1;
    border-radius: 8px;
    padding: 15px;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
    background: rgba(23, 37, 90, 0.85);
  }
  
  .roles-box h3, .achievements-box h3 {
    color: #fff;
    text-align: center;
    font-size: 1.5rem;
    margin-bottom: 15px;
  }
  
  .roles-list, .achievements-list {
    display: flex;
    flex-direction: column;
    gap: 15px;
    padding: 0;
    list-style: none;
  }
  
  .role-item {
    display: flex;
    align-items: center;
    gap: 20px;
    padding: 10px;
    border-radius: 8px;
    background: rgba(201, 64, 101, 0.8);
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.2);
    opacity: 1;
  }
  
  .role-left {
    display: flex;
    align-items: center;
    gap: 10px;
    flex: 1;
  }
  
  .role-image {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    object-fit: cover;
  }
  
  .role-left p {
    text-align: left;
    font-size: 1rem;
    color: #fff;
  }
  
  .role-right {
    text-align: right;
  }
  
  .role-date {
    font-size: 0.9rem;
    font-weight: bold;
    color: #fff;
  }
  
  .achievement-item {
    padding: 10px;
    border-radius: 4px;
    background: rgba(201, 64, 101, 0.8);
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
    color: #fff;
    font-size: 1rem;
    opacity: 1;
  }
  
  @media (min-width: 321) and (max-width: 375px) {
    .hero {
      min-height: 135vh;
      flex-direction: column;
      padding-top: 150px;
  }

  .hero-content {
      flex-direction: column;
      align-items: center;
      text-align: center;
      width: 100%;
  }

  .hero-text p {
    text-align: left;
  }

  .hero-image {
    width: 65%;
    height: 65%;
    margin-right: 0;
    margin-bottom: 10px;
  }

  .hero-text {
      max-width: 90%;
      margin: 0 auto;
  }

  .hero-text h2 {
    font-size: 28px;
  }

  .social-links {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      margin-top: 5px;
      gap: 15px;
      justify-content: center;
  }

  .social-links a {
      font-size: 16px;
  }

  .social-links img.icon,
  .social-links .gmail-icon {
      width: 24px;
      height: 24px;
  }
  
  .section-links {
      display: list-item;
      flex-direction: row; /* Arrange links side by side */
      justify-content: space-between; /* Center the links horizontally */
      width: 100%;
      margin-top: 10px;
      box-sizing: border-box;
    }

    .section-links a {
      font-size: 16px; /* Adjust font size for mobile */
      text-decoration: none; /* Remove underlines */
      color: #ffffff; /* Adjust color as needed */
      padding: 0px 5px; /* Add padding for better touch targets */
      border-radius: 5px; /* Optional: Add rounded corners */
      transition: background-color 0.3s ease; /* Smooth hover effect */
  }
  
    .projects-grid {
      grid-template-columns: 1fr;
    }
  
    .experience-container {
      width: 100%;
    }
  
    .experience-card {
      flex-direction: column;
      align-items: center;
      text-align: left;
    }
  
    .experience-logo {
      margin-right: 0;
      margin-bottom: 10px;
    }
  
    .skills-container {
      flex-direction: column;
      align-items: center;
    }
  
    .skills-category {
      max-width: 100%;
    }
  
    .roles-achievements-container {
      flex-direction: column;
    }
  
    .role-item {
      flex-direction: column;
      align-items: center;
      text-align: center;
    }
  
    .role-left {
      flex-direction: column;
      align-items: center;
    }
  
    .role-right {
      margin-top: 10px;
      text-align: center;
    }
  }

  @media (min-width: 376px) and (max-width: 425px) {
    .hero {
      min-height: 135vh;
      flex-direction: column;
      padding-top: 80px;
  }

  .hero-content {
      flex-direction: column;
      align-items: center;
      text-align: center;
      width: 100%;
  }

  .hero-text p {
    font-size: 18px;
    text-align: left;
  }

  .hero-image {
    width: 90%;
    height: 90%;
    margin-right: 0;
    margin-bottom: 10px;
  }

  .hero-text {
      max-width: 90%;
      margin: 0 auto;
  }

  .hero-text h2 {
    font-size: 30px;
  }

  .social-links {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      margin-top: 10px;
      gap: 15px;
      justify-content: center;
  }

  .social-links a {
      font-size: 20px;
  }

  .social-links img.icon,
  .social-links .gmail-icon {
      width: 26px;
      height: 26px;
  }
  
  .section-links {
      display: list-item;
      flex-direction: row; /* Arrange links side by side */
      justify-content: space-between; /* Center the links horizontally */
      width: 100%;
      margin-top: 20px;
      box-sizing: border-box;
    }

    .section-links a {
      font-size: 18px; /* Adjust font size for mobile */
      text-decoration: none; /* Remove underlines */
      color: #ffffff; /* Adjust color as needed */
      padding: 0px 3px; /* Add padding for better touch targets */
      border-radius: 5px; /* Optional: Add rounded corners */
      transition: background-color 0.3s ease; /* Smooth hover effect */
  }
  
    .projects-grid {
      grid-template-columns: 1fr;
    }
  
    .experience-container {
      width: 100%;
    }
  
    .experience-card {
      flex-direction: column;
      align-items: center;
      text-align: left;
    }
  
    .experience-logo {
      margin-right: 0;
      margin-bottom: 10px;
    }
  
    .skills-container {
      flex-direction: column;
      align-items: center;
    }
  
    .skills-category {
      max-width: 100%;
    }
  
    .roles-achievements-container {
      flex-direction: column;
    }
  
    .role-item {
      flex-direction: column;
      align-items: center;
      text-align: center;
    }
  
    .role-left {
      flex-direction: column;
      align-items: center;
    }
  
    .role-right {
      margin-top: 10px;
      text-align: center;
    }
  }

  @media (min-width: 426px) and (max-width: 768px) {
    .hero {
      min-height: 135vh;
      flex-direction: column;
      padding-top: 80px;
  }

  .hero-content {
      flex-direction: column;
      align-items: center;
      text-align: center;
      width: 100%;
  }

  .hero-text p {
    font-size: 20px;
    text-align: left;
  }

  .hero-image {
    width: 90%;
    height: 90%;
    margin-right: 0;
    margin-bottom: 10px;
  }

  .hero-text {
      max-width: 90%;
      margin: 0 auto;
  }

  .hero-text h2 {
    font-size: 30px;
  }

  .social-links {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      margin-top: 10px;
      gap: 15px;
      justify-content: center;
  }

  .social-links a {
      font-size: 22px;
  }

  .social-links img.icon,
  .social-links .gmail-icon {
      width: 28px;
      height: 28px;
  }
  
  .section-links {
      display: list-item;
      flex-direction: row; /* Arrange links side by side */
      justify-content: space-between; /* Center the links horizontally */
      width: 100%;
      margin-top: 20px;
      box-sizing: border-box;
    }

    .section-links a {
      font-size: 22px; /* Adjust font size for mobile */
      text-decoration: none; /* Remove underlines */
      color: #ffffff; /* Adjust color as needed */
      padding: 0px 3px; /* Add padding for better touch targets */
      border-radius: 5px; /* Optional: Add rounded corners */
      transition: background-color 0.3s ease; /* Smooth hover effect */
  }
  
    .projects-grid {
      grid-template-columns: 1fr;
    }
  
    .experience-container {
      width: 100%;
    }
  
    .experience-card {
      flex-direction: column;
      align-items: center;
      text-align: left;
    }
  
    .experience-logo {
      margin-right: 0;
      margin-bottom: 10px;
    }
  
    .skills-container {
      flex-direction: column;
      align-items: center;
    }
  
    .skills-category {
      max-width: 100%;
    }
  
    .roles-achievements-container {
      flex-direction: column;
    }
  
    .role-item {
      flex-direction: column;
      align-items: center;
      text-align: center;
    }
  
    .role-left {
      flex-direction: column;
      align-items: center;
    }
  
    .role-right {
      margin-top: 10px;
      text-align: center;
    }
  }

  @media (max-width: 374px) {
    .hero {
      min-height: 175vh;
      flex-direction: column;
      padding-top: 85px;
  }

  .hero-content {
      flex-direction: column;
      align-items: center;
      text-align: center;
      width: 100%;
  }

  .hero-text p {
    font-size: 16px;
    text-align: left;
  }

  .hero-image {
    width: 75%;
    height: 75%;
    margin-right: 0;
    margin-bottom: 10px;
  }

  .hero-text {
      max-width: 90%;
      margin: 0 auto;
  }

  .hero-text h2 {
    font-size: 30px;
  }

  .social-links {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      margin-top: 10px;
      gap: 15px;
      justify-content: center;
  }

  .social-links a {
      font-size: 16px;
  }

  .social-links img.icon,
  .social-links .gmail-icon {
      width: 20px;
      height: 20px;
  }
  
  .section-links {
      display: list-item;
      flex-direction: row; /* Arrange links side by side */
      justify-content: space-between; /* Center the links horizontally */
      width: 100%;
      margin-top: 20px;
      box-sizing: border-box;
    }

    .section-links a {
      font-size: 18px; /* Adjust font size for mobile */
      text-decoration: none; /* Remove underlines */
      color: #ffffff; /* Adjust color as needed */
      padding: 0px 3px; /* Add padding for better touch targets */
      border-radius: 5px; /* Optional: Add rounded corners */
      transition: background-color 0.3s ease; /* Smooth hover effect */
  }
  
    .projects-grid {
      grid-template-columns: 1fr;
    }
  
    .experience-container {
      width: 100%;
    }
  
    .experience-card {
      flex-direction: column;
      align-items: center;
      text-align: left;
    }
  
    .experience-logo {
      margin-right: 0;
      margin-bottom: 10px;
    }
  
    .skills-container {
      flex-direction: column;
      align-items: center;
    }
  
    .skills-category {
      max-width: 100%;
    }
  
    .roles-achievements-container {
      flex-direction: column;
    }
  
    .role-item {
      flex-direction: column;
      align-items: center;
      text-align: center;
    }
  
    .role-left {
      flex-direction: column;
      align-items: center;
    }
  
    .role-right {
      margin-top: 10px;
      text-align: center;
    }
  }