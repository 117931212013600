/* Projects.module.css */
.projects {
  font-family: 'Roboto', sans-serif;
  background: url('projects.png') no-repeat center center/cover;
  position: relative;
  overflow: hidden;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  padding-top: 6rem;
}

.projects::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(to right, rgba(23, 37, 90, 0.8), rgba(201, 64, 101, 0.8)); 
  z-index: 1;
}

.mainContent {
  position: relative;
  z-index: 2;
  padding: 2rem;
  color: #000; 
}

.title {
  color: white; 
  text-align: center;
  margin-bottom: 2rem;
  margin-top: 2rem;
}

.ongoing {
  text-align: center;
  color: #f1eded; 
  margin-top: 2rem;
}

.projectList {
  display: flex;
  flex-wrap: wrap;
  gap: 2rem; /* Consistent spacing between cards */
  padding: 2rem;
}

.projectCard {
  height: 550px; /* Fixed height for all cards */
  width: 500px;  /* Fixed width instead of max-width */
  margin: 1rem;
 /* background-color: rgba(255, 255, 255, 0.9);*/
  background-color: rgba(255, 255, 255, 0.224);
  border-radius: 8px;
  overflow: hidden;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  text-align: center;
  color: #000;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  transition: transform 0.2s, box-shadow 0.2s; /* Smooth transition for hover effect */
}

.projectCard p {
  padding: 0 1rem 1rem;
  color: #333; 
  text-align: left;
}

.projectCard img {
  width: 100%;
  height: 350px;
  object-fit: cover;
}

.projectCard:hover {
  transform: translateY(-10px);
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.25);
}

.centeredText {
  text-align: center;
  padding: 0 1rem; 
}

.projectCard .btn {
  display: inline-block;
  background-color: #007bff !important;
  color: white;
  text-decoration: none;
  padding: 0.5rem 1rem;
  border-radius: 4px;
  margin: 0 1rem 1rem;
  transition: background-color 0.2s ease-in-out;
}

.projectCard .btn:hover {
  background-color: #0056b3 !important;
  color: #f7f7f7 !important;
}

@media (max-width: 768px) {
  .projectList {
      flex-direction: column; /* Stack items vertically on small screens */
      align-items: center; /* Center items */
  }

  .projectCard {
      max-width: 100%; /* Allow cards to take full width */
  }
}

/* Header styles */
header {
  z-index: 3;
  background-color: rgba(0, 0, 0, 0.8);
  padding: 1rem;
  position: fixed;
  width: 100%;
  top: 0;
  left: 0;
}

.projectsWrapper {
  display: flex;
  justify-content: center;
  gap: 3rem; /* Consistent spacing between cards */
  margin: 0 auto;
  max-width: 1200px; /* Maximum width for larger screens */
  padding: 2rem;
}

.ongoingTechnical, .ongoingResearch {
  flex: 1; 
  margin-right: 20px; 
}
