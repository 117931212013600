.events {
  font-family: 'Roboto', sans-serif;
  background: url('./event.jpg') no-repeat center center/cover;
  position: relative;
  overflow: hidden;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  padding-top: 2rem;
}

.events::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(to right, rgba(23, 37, 90, 0.8), rgba(201, 64, 101, 0.8));
  z-index: 1;
}

.mainContent {
  position: relative;
  z-index: 2;
  padding: -100rem;
  color: #000;
}

.title {
  color: #f8f2f2;
  text-align: center;
}

.text {
  color: white;
  text-align: center;
  font-size: 1.1rem;
}

.upcoming, .past, .podcastTitle {
  color: #f1eded;
  text-align: center;
}

.eventList {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  padding: -10rem;
}

.eventCard {
  width: 100%;
  max-width: 600px;
  margin-bottom: 2rem;
  background-color: rgba(255, 255, 255, 0.9);
  border-radius: 8px;
  overflow: hidden;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  text-align: center;
  position: relative;
}

.eventCard img {
  width: 100%;
  height: auto;
  object-fit: cover;
}

.eventDetails {
  padding: -1rem;
  color: #000;
}

.eventDetails h2 {
  margin: 0;
  color: #000;
}

.description, .date {
  color: #333;
}

.highlightsList {
  text-align: left;
  color: #000;
}

.conclusion {
  color: #333;
  text-align: center;
}

.btn {
  display: inline-block;
  background-color: #007bff !important;
  color: white;
  text-decoration: none;
  padding: 0.5rem 1rem;
  border-radius: 4px;
  margin: 0.5rem 0;
  transition: background-color 0.2s ease-in-out;
}

.btn:hover {
  background-color: #0056b3 !important;
  color: #f7f7f7 !important;
}

.conclusion {
  color: #333;
  padding: 0.5rem 1rem;
  margin: 0.5rem 0;
  text-align: center;
}

@media (max-width: 768px) {
  .eventList {
    flex-direction: column;
    align-items: stretch;
    padding: -100rem;
  }

  .eventCard {
    max-width: 100%; 
  }

  .eventCard img {
    width: 100%; 
    height: auto;
    object-fit: cover;
  }

  .eventDetails {
    padding: 1rem; 
  }

  .eventDetails h2 {
    font-size: 1rem; 
  }

  .description,
  .date,
  .conclusion {
    font-size: 0.7rem; 
  }

  .highlightsList {
    font-size: 0.7rem; 
  }

  .btn {
    padding: 0.2rem 0.5rem;
    font-size: 0.7rem;
  }
}