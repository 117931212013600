.carousel {
  /* position: relative; */
  display: flex;
  justify-content: center;
  align-items: center;
  width: 600px;
  height: 500px;
}

.images{
  width: 100%;
  height: 100%;
  object-fit: cover;

}
img{
  height: '500px'; 
  width: '100vw';
}

@media screen and (max-width: 600px) { 
  img {
    height: 50px;
    width: 50px;
  }
}
.slide {
  /* position: fixed; */
  border-radius: 0.5rem;
  box-shadow: 0px 0px 7px #666;
  width: 100%;
  height: 100%;
  transition: transform 0.5s ease-in-out, opacity 0.5s ease-in-out;
}

.slide-active {
  transition: transform 0.5s ease-in-out, opacity 0.5s ease-in-out;
  opacity: 1; /* Make the active slide visible */
  transform: scale(1); /* Scale the active slide back to normal */
}

@media screen and (max-width: 600px) { 
  .slide-active {
    transform: scale(0.7);
  }
}

.slide-hidden {
  /* position: fixed; */
  display: none;
}

.arrow {
  position: absolute;
  filter: drop-shadow(0px 0px 5px #555);
  width: 2rem;
  height: 2rem;
  color: white;
}

.arrow:hover {
  cursor: pointer;
}

.arrow-left {
  z-index: 1000;
  left: 1rem;
}

.arrow-right {
  z-index: 1000;
  right: 1rem;
}

.indicators {
  display: flex;
  position: absolute;
  bottom: 1rem;
}

.indicator {
  background-color: white;
  height: 0.7rem;
  width: 0.5rem;
  border-radius: 100%;
  border: none;
  outline: none;
  box-shadow: 0px 0px 5px #555;
  margin: 0 0.2rem;
  cursor: pointer;
}



.indicator-inactive {
  background-color: grey;
}

.indicator:hover {
  background-color: rgba(211, 211, 211, 0.308); /* Subtle hover effect */
}