  .contact {
    position: relative;
    font-family: 'Arial', sans-serif;
    padding-top: 110px;
    padding-bottom: 50px;
  }

  .videoBackground {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: -1;
  }

  .videoBackground video {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  .content {
    position: relative;
    z-index: 2;
    color: #f5f5f5;
    padding: 2rem;
    background-color: rgba(0, 0, 0, 0.6);
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3);
    max-width: 800px;
    margin-left: auto;
    margin-right: auto;
  }

  form {
    background-color: #ffffff;
    padding: 2.5rem;
    border-radius: 10px;
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
    max-width: 600px;
    margin: 0 auto;
    position: relative;
    z-index: 1;
  }

  main {
    max-width: 800px;
    margin: 0 auto;
    padding: 2rem;
    padding-top: 120px;
    padding-bottom: 80px;
    width: 100%;
  }
  
  h1 {
    text-align: center;
    font-size: 3rem;
    margin-top: 10px;
    margin-bottom: 1.5rem;
    color: #ffffff;
    font-family: 'Arial', sans-serif;
    font-weight: 700;
    text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.6);
  }
  
  .contactUsTitle {
    color: #f5f5f5;
    font-size: 2rem;
    font-weight: 600;
    text-transform: uppercase;
    margin-bottom: 1rem;
  }
  
  .titleDesc {
    font-size: 1.2rem; 
    font-weight: 400; 
    color: #f5f5f5; 
    text-align: center; 
    margin: 1.5rem 0; 
    line-height: 1.6; 
    padding: 0.5rem 1rem; 
    border: 2px solid #ea3f80; 
    border-radius: 8px; 
    background-color: rgba(0, 0, 0, 0.5); 
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3); 
    max-width: 600px; 
    margin-left: auto; 
    margin-right: auto;
  }
  
  .formGroup {
    margin-bottom: 1.75rem;
    font-weight: 600;
    color: #333333;
    font-size: 1.1rem;
    padding: 2px;
  }
  
  .formGroup label {
    display: block;
    margin-bottom: 0.75rem;
    font-weight: 600;
    color: #333333;
    font-size: 1.1rem;
  }
  
  .formGroup input,
  .formGroup textarea {
    width: 100%;
    padding: 1rem;
    border: 1px solid #ea3f80;
    border-radius: 4px;
    font-size: 1rem;
  }
  
  textarea {
    height: 200px;
    resize: vertical;
    line-height: 1.5;
  }
  
  .formGroup button[type="submit"] {
    display: block;
    margin: 1.5rem auto 0;
    padding: 0.75rem 2rem;
    background-color: #ea3f80;
    color: #ffffff;
    border: none;
    border-radius: 4px;
    font-size: 1rem;
    font-weight: bold;
    cursor: pointer;
    transition: background-color 0.3s ease, transform 0.3s ease, box-shadow 0.3s ease;
  }
  
  .formGroup button[type="submit"]:hover {
    background-color: #d6336c; 
    transform: scale(1.05);
    box-shadow: 0 6px 12px rgba(0, 0, 0, 0.2);
  }
  
  .successMessage {
    background-color: #81e698; 
    color: #155724;
    padding: 2rem;
    border-radius: 8px;
    text-align: center;
  }
  
  .successMessage h2 {
    margin-bottom: 1rem;
  }
  
  .notificationPopup {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: #81e698; 
    padding: 2rem;
    border-radius: 8px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    z-index: 1001;
    animation: fadeIn 0.3s ease-in-out;
  }
  
  .notificationContent {
    text-align: center;
  }
  
  .notificationContent h2 {
    margin-bottom: 1rem;
  }
  
  .notificationContent p {
    margin-bottom: 2rem;
  }
  
  .notificationContent button {
    background-color: #ea3f80;
    color: #ffffff;
    border: none;
    padding: 1rem;
    border-radius: 4px;
    font-size: 1rem;
    font-weight: bold;
    cursor: pointer;
    transition: background-color 0.3s ease, transform 0.3s ease, box-shadow 0.3s ease;
  }
  
  .notificationContent button:hover {
    background-color: #d6336c; 
    transform: scale(1.05);
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    color: #ffffff;
  }
  
  @keyframes fadeIn {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }
  
  @media (max-width: 768px) {
    .content {
      padding: 1rem;
    }
  
    .formGroup {
      font-size: 1rem;
    }
  
    .formGroup input,
    .formGroup textarea {
      padding: 0.75rem;
    }
  
    .formGroup button[type="submit"] {
      padding: 0.5rem 1.5rem;
    }
  }
  